import { useState } from 'react';

import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";

interface ILoginProps {
  login: (email: string) => void;
  setActiveType: (boolean) => void;
  setError: (any) => void;
}
const Login = (props: ILoginProps) => {
  const { login, setActiveType, setError } = props;
  
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const [email, setEmail] = useState(params.get('email') ? params.get('email') : '');

  const backClicked = () => {
    setError('');
    setActiveType('chooseSignUpOrLogin');
  }
  
  const loginClicked = () => {
    login(email)
  };

  return (
    <Row className="px-3 pt-5">
      <Col xs={12}>
        <InputGroup className="mb-3">
          <FormControl value={email} onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            aria-label="Email address"
            aria-describedby="basic-addon2"
          />
        </InputGroup>
      </Col>
      <Col className="text-right" xs={12}>
        <Button className='mx-2 pink' onClick={() => backClicked()} variant="dark">Back</Button>
        <Button className="bold pink" disabled={email.length < 3} onClick={loginClicked} variant="outline-secondary">Login</Button>
      </Col>
    </Row>
  )
}
export default Login;