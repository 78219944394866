import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Col, Container, ListGroup, Offcanvas, Row } from "react-bootstrap";
import firebase from "firebase/compat/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/functions";

import "./App.css";
import Acceptance from './components/Acceptance';
import AllMessages from "./components/AllMessages";
import Contact from "./components/Contact";
import CreatePost from "./components/CreatePostPage";
import EditProfilePage from "./components/EditProfilePage";
import ExplorePage from "./components/ExplorePage";
import FollowerPage from "./components/FollowersPage";
import FollowingPage from "./components/FollowingPage";
import HomePage from "./components/HomePage";
import Messaging from "./components/Messaging";
import NotFound from './components/NotFound';
import Notifications from "./components/Notifications";
import PickUsernamePage from "./components/SignIn/PickUsernamePage";
import PostComments from "./components/PostComments";
import PostLikes from "./components/PostLikes";
import Privacy from './components/Privacy';
import ProfilePage from "./components/ProfilePage";
import SettingsPage from "./components/SettingsPage";
import SignIn from "./components/SignIn/SignUpLoginPage";
import TheNittyGritty from "./components/TheNittyGritty";
import ThingsContext from './context/Context';
import UserFeedPage from "./components/UserFeedPage";

firebase.initializeApp({
  apiKey: "AIzaSyAdI5RLjAwxPZekQKnCQ7gBHh2gePdZOjM",
  authDomain: "camlyfev2.firebaseapp.com",
  projectId: "camlyfev2",
  storageBucket: "camlyfev2.appspot.com",
  messagingSenderId: "686398110684",
  appId: "1:686398110684:web:abb32eab7f489a486b6d19",
  measurementId: "G-LCHZM51MHD",
});

const App = () => {
  const auth = getAuth();
  const db = getFirestore();

  const [explorePosts, setExplorePosts] = useState([]);
  const [camlyfeUsers, setCamlyfeUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [isMale, setIsMale] = useState(false);
  const [isFemale, setIsFemale] = useState(true);
  const [isNb, setIsNb] = useState(false);
  const [lastFetched, setLastFetched] = useState(null);
  const [menuState, setMenuState] = useState(0);
  const [payments, setPayments] = useState([]);
  const [posts, setPosts] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);

  const value = { camlyfeUsers, currentUser, setCurrentUser, setCamlyfeUsers, isMale, isFemale, isNb, payments, setPayments,
      setIsFemale, setIsMale, setIsNb, explorePosts, lastFetched, posts, setExplorePosts, 
      setLastFetched, setPosts,  };

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence);
  }, []);

  const generateJsx = () => {
    switch(menuState) {
      case 3: return <div>
        All Camlyfe transactions can be refunded on a case by case basis. Camlyfe reserves the right to postpone, hold, cancel, or 
        report any and all transactions. We do this for the safety of our users, in our ongoing effort to prevent illegal content being uploaded to our platform. 
        We also do this to protect our artists, so that their content cant be stolen by other users.
        <br/><br/>
        <br/> <br/>
        If you feel that a transaction has been done in your name illegally, please contact us immediately at https://camlyfe.com/contact. If you feel that 
        someone else is using photos of you without your consent, please contact us immediately.
      </div>
      case 2: return <Privacy />
      case 1: return <Acceptance />
      default: return <TheNittyGritty />
    }
  }

  const generateTitle = () => {
    switch(menuState) {
      case 3: return <div>Cancellations/Refunds</div>;
      case 2: return <div>Privacy Policy</div>;
      case 1: return <div>Terms & Conditions</div>;
      default: return <a className='menu-title-home' href='https://camlyfe.com'>camlyfe</a>;
    }
  }

  return (
    <ThingsContext.Provider value={value}>
      <div className="darkMode">
        <BrowserRouter>
          <Container className="camlyfe-container container-fluid">
            <Routes>
              <Route path="/" element={<SignIn />} />
              {/* <Route path="/allmessages" element={<AllMessages />} />
              <Route
                path="/contact"
                element={<Contact setShowOverlay={setShowOverlay} />}
              />
              <Route path="/createpost" element={<CreatePost />} />
              <Route path="/editprofile" element={<EditProfilePage />} />
              <Route
                path="/explore"
                element={<ExplorePage setShowOverlay={setShowOverlay} />}
              />
              <Route path="/followers" element={<FollowerPage />} />
              <Route path="/following" element={<FollowingPage />} />
              <Route
                path="/"
                element={<HomePage setShowOverlay={setShowOverlay} />}
              />
              <Route path="/messaging" element={<Messaging />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/pickusername" element={<PickUsernamePage />} />
              <Route
                path="/postcomments"
                element={<PostComments setShowOverlay={setShowOverlay} />}
              />
              <Route path="/postlikes" element={<PostLikes />} />
              <Route
                path="/profile"
                element={<ProfilePage setShowOverlay={setShowOverlay} />}
              />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/userfeed" element={<UserFeedPage />} />
              <Route path='*' element={<NotFound />} /> */}
            </Routes>
          </Container>
        </BrowserRouter>
        <Offcanvas className="overlay-dark"
          show={showOverlay}
          onHide={() => setShowOverlay(false)}>
          <Offcanvas.Header closeButton className='open-nav'>
            <Offcanvas.Title>
              <Row>
                <Col>
                  {generateTitle()}
                </Col>
              </Row>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="sidenav-text">
            <Row>
              <Col>
                {generateJsx()}
                <div className="bottom-absolute menus-mb py-0 px-3">
                  <ListGroup as="ol" className="cursor mx-4">
                    <ListGroup.Item as="li" onClick={() => setMenuState(0)} className={menuState == 0 ? 'bold text-white' : ''}>
                      ABOUT
                    </ListGroup.Item>
                    <ListGroup.Item as="li" onClick={() => setMenuState(1)} className={menuState == 1 ? 'bold text-white' : ''}>
                      TERMS & CONDITIONS</ListGroup.Item>
                    <ListGroup.Item as="li" onClick={() => setMenuState(2)} className={menuState == 2 ? 'bold text-white' : ''}>
                      PRIVACY POLICY
                    </ListGroup.Item>
                    <ListGroup.Item as="li" onClick={() => setMenuState(3)} className={menuState == 3 ? 'bold text-white' : ''}>
                      CANCELLATIONS/REFUNDS
                    </ListGroup.Item>
                    <div className='contact-lynk'>
                        <a className='list-group-a' href='https://camlyfe.com/contact'>CONTACT US</a>
                        <a href='http://twitter.com/camlyfeapp' target='_blank'>
                          <i class="bi bi-twitter right" style={{ marginRight: '32px', marginTop: '-7px'}} />
                        </a>
                    </div>
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </ThingsContext.Provider>
  );
};

export default App;
