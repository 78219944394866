import '../../boobs.png';
import '../../dick.png';
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import bewb from '../../boobs.png';
import dicky from '../../dick.png';
import Login from "./Login";
import SignUp from './SignUp';
import { validateEmail } from "../../utils/globalFunctions";

const SignIn = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [activeType, setActiveType] = useState("chooseSignUpOrLogin");
  const [confirmMessage, setConfirmMessage] = useState(``);
  const [didJustCreateAccount, setDidJustCreateAccount] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreatingAccount, setShowCreatingAccount] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);

  onAuthStateChanged(auth, user => {
    if(user) {
        navigate('/')
    }
  });

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
        if(params.get('email')) {
          login(params.get('email'))
          return;
        }
        setDidJustCreateAccount(true);
        setShowCreatingAccount(true);
        setLoading(false);
        setTimeout(() => {
          setActiveType('login');
          setShowCreatingAccount(false);
        }, 2000);
    } else {
      if(params.get("newuser")) {
        setActiveType('signup')
      }
      setLoading(false);
    }
  }, []);

  const sendEmailConfirmation = async (email) => {
    const actionCodeSettings = {
      //url: `http://localhost:3000/signin/?email=${email}`,
      url: `https://camlyfe.com/signin/?email=${email}`,
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(auth, email, actionCodeSettings).then((res) => {
        setConfirmMessage(
          `Woot! We just sent a login link to ${email}. Go check it out!`
        );
        setLoading(false);
      }).catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  
  const createAccount = (email) => {
    if (!validateEmail(email)) {
      setError("Enter a valid email");
    } else {
      if(email.includes('@bvhrk.com') || email.includes('@ishop2k')){
        setError('Get a real fucking email address, dickhead.')
        return;
      }
      setLoading(true);
      setError("");
      sendEmailConfirmation(email);
    }
  };

  const generateJSX = () => {
    switch (activeType) {
      case "signup":
        return (
          <SignUp createAccount={createAccount} error={error} setActiveType={setActiveType} setError={setError} />
        );
      case "login":
        return <Login login={login} setActiveType={setActiveType} setError={setError} />;
      default:
        return (
          <Row className="fixed-bottom py-4 px-4">
            <Col className='mb-3' xs='12'>
              <small className='text-pink'>MUST BE 18+ TO ENTER</small>
            </Col>
            <Col>
              <div className="d-grid gap-3">
                <Button className="bold pink"
                  disabled
                  onClick={() => setActiveType("signup")}
                  size="md"
                  variant="outline-dark">
                  I'm over 18. Sign up.
                </Button>
                <Button className="bold pink"
                  disabled
                  onClick={() => setActiveType("login")}
                  size="md"
                  variant="outline-dark">
                  Already have an account?
                </Button>
              </div>
            </Col>
          </Row>
        );
    }
  };

  const login = async (email) => {
    setLoading(true);
    console.log('email', validateEmail(email))
    if (!validateEmail(email)) {
      setError("Enter a valid email");
      setLoading(false)
      return false;
    } else {
      try {
        if(isSignInWithEmailLink(auth, window.location.href)) {
          await signInWithEmailLink(auth, email, window.location.href)
        } else {
          sendEmailConfirmation(email);
        }

      } catch (err) {
        setLoading(false);
        setError('Oops! Couldnt log in', err.message)
      }

    }
  };

  return (
    <div>
      <div className="full-height text-center">
        {loading || showCreatingAccount ? (
          loading ? <div className="middle text-center">
            <h3 className='mb-5 text-grey'>Logging in..</h3>
            <Spinner animation="grow" variant="pink" />
          </div> :
          <div className="full-height">
          <div className="middle text-center">
              <h3 className='mb-4 text-pink'>Loading..</h3>
              <img alt='camlyfe tit boob large' className='bewb-large'
                  src={dicky}  />
          </div>
        </div>

        ) : (
          <>
            {confirmMessage && confirmMessage.length > 0 ? (
              <div className='fixed-middle'>
                <img alt='camlyfe tit boob' className='bewb' src={bewb}  />
                <p className='mt-3 mx-2 text-pink'>{confirmMessage}</p>
                <br/>
                <small className='text-pink'>Dont see it? Check your spam folder!</small>
              </div>
              
            ) : (
              <>
                <h2 className="header text-white" onClick={() => navigate('/')}>CAMLYFE</h2>
                <h6 className="text-pink mt-4 mb-2" onClick={() => navigate('/')}>it's&nbsp; for&nbsp; the&nbsp; ti
                <img  alt='camlyfe tit' className='bewb'src={bewb}  />ies..</h6>
                {didJustCreateAccount && <p className="text-pink my-1">Reenter your email to login!</p>}
                {generateJSX()}
              </>
            )}
          </>
        )}
        
        {error && <p className="text-red pt-3">{error}</p>}
      </div>
    </div>
  );
};
export default SignIn;
