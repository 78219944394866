

import { Col, Row } from "react-bootstrap";

const TheNittyGritty = () => {

    return (
        <div>
            <Row>
                <Col xs='12'>
                    <p> 
                        <span className='bold text-pink'>Post like Insta. Sell like OnlyFans. </span><br/><br/>
                        Camlyfe is a nudity friendly social media platform that allows anyone over the age of 18 with a valid drivers license 
                        to post their content, as well as sell it at any price they see fit.<br/> <br/>
                        Users can buy content with cryptocurrency or a debit card, and<span className='bold text-pink'> content creators keep 95% of the earnings.</span>
                        <br/>
                        <br/> 
                        Welcome to Camlyfe. We are <span className='text-pink bold'>fair trade porn.</span>
                        <br/>
                        <br/>
                        <br/> 
                       <div style={{ backgroundColor: 'grey', padding: '10px', borderRadius: '5px'}}>
                       <span className='bold text-black' style={{ textDecoration: 'underline'}}>EXCITING NEWS!</span>  Get paid $100 cash for every 100 followers
                        you bring to Camlyfe. Email <span style={{ textDecoration: 'underline'}}>support@camlyfe.com</span> with your social media handles to get started!
                       </div>
                    </p>
                </Col>
            </Row>
        </div>
    )
}
export default TheNittyGritty;