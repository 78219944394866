import { useState } from "react";

import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";

interface ISignUpProps {
    createAccount: (email: string) => void;
    error: string;
    setActiveType: (string) => void;
    setError: (string) => void;
}
const SignUp = (props: ISignUpProps) => {
    const { createAccount, error, setActiveType, setError } = props;
    const [email, setEmail] = useState('');

    const backClicked = () => {
        setError('');
        setActiveType('chooseSignUpOrLogin');
    }

    const userClickedCreateAccount = () => {
        createAccount(email)
    }

    return (
        <Row className="mx-1 pt-5">
            <Col xs={12}>
                <InputGroup className="mb-3">
                    <FormControl onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email address"
                        aria-label="Email address"
                        aria-describedby="basic-addon2"
                    />
                </InputGroup>
            </Col>
            <Col className="text-right mt-3" xs={12}>
                <Button className='mx-2 pink' onClick={() => backClicked()} variant="dark">Back</Button>
                <Button className="bold pink" disabled={email.length < 5} onClick={userClickedCreateAccount} variant="outline-secondary">Login to Camlyfe</Button>
            </Col>
        </Row>

    )
}
export default SignUp;