import { useContext, useEffect, useState } from 'react';

import { Alert, Badge, Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import BlockUser from './BlockUser';
import Footer from './Footer';

import GridPhoto from './GridPhoto';
import SEO from './Helmet';
import ThingsContext from '../context/Context';
import '../boobs.png';

const spankpay = require("spankpay");

const ProfilePage = (props) => {
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();
    const { search } = useLocation();
    const { currentUser, explorePosts, setCurrentUser } = useContext(ThingsContext);

    let params = new URLSearchParams(search);
    let userFromQueryString = params.get('userprofile');

    const [user, setUser] = useState({});
    const isOwnerOfProfile = (auth && auth.currentUser && auth.currentUser.displayName == userFromQueryString);

    const [block, setBlock] = useState(false);
    const [followerCount, setFollowerCount] = useState(0);
    const [isAlreadyFollowing, setIsAlreadyFollowing] = useState(false);
    const [blockPost, setBlockPost] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [remainingTime, setRemainingTime] = useState(null)
    const [showAlert, setShowAlert] = useState(false);

    const [bubble, setBubble] = useState({
        border: '3px solid pink',
        backgroundImage: '',
        borderRadius: '21px',
        marginLeft: '0',
        height: '100px',
        width: '100px',
    });

    useEffect(() => {
        getUser(decodeURI(userFromQueryString));
        getFollowers(userFromQueryString).then(res => {
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {     
        if(window) {
             window.scroll(0, 0);
        }
    }, [])

    useEffect(async () => {
        if(user && user.photoUrl){
            setBubble({
                border: '3px solid pink',
                backgroundImage: `url(${user.photoUrl})`,
                backgroundSize: 'cover',
                borderRadius: '21px',
                marginLeft: '0',
                height: '100px',
                width: '100px',
            })
        }
        if(user) {
            if(!isOwnerOfProfile && user.subscriptionPrice > 0) {
                let isBlocked = await checkForUserWithoutSubscription();
                setBlock(isBlocked);
            } else {
                if(explorePosts && explorePosts.length > 0) {
                    setPosts(explorePosts.filter(p => p.userName == userFromQueryString && p.active))
                } else {
                    navigate('/');
                }
            }
        }
    }, [user]);
    
    const addProfilePicture = () => {
        if(isOwnerOfProfile) {
            navigate('/createpost?profilepic=true');
        }
    }

    const buyPost = () => {
        if (!currentUser || !user.subscriptionPrice || !user || !user.uid) {
          return;
        }
        let u = currentUser;
        u.blockedList = [];
        console.log('only ting', user.subscriptionPrice)
        spankpay.show({
          allowPartial: true,
          apiKey: "pk_furious_jolt_zcz8zKIy0iBzQlj1ZOf3bA6PKDhK23vdvF8bUdMAXINj",
          amount: user.subscriptionPrice,
          fiatEnabled: true,
          currency: "USD",
          callbackUrl: "https://us-central1-camlyfev2.cloudfunctions.net/camlyfePayment",
          description: `Subscription: ${currentUser.name} is purchasing ${user.name}`,
          metadata: {
            fromUserId: currentUser.uid,
            post: "null",
            subscriptionId: user.uid,
            toUserId: user.uid,
            user: u,
          },
          onClose: function () {
            
          },
          onPayment: function (payment) {
            updateUser();
            setShowAlert(true);
          },
        });
    };

    const checkForUserWithoutSubscription = () => {
        let res = true; 
        if(currentUser && currentUser.paidPosts && currentUser.paidPosts.length > 0) {
            currentUser.paidPosts.forEach(p => {
                if(user && p.subscriptionId && p.subscriptionId == user.uid) {
                    let date = new Date(p.date.seconds*1000)
                    let d = moment(new Date()).diff(date, 'days', true);
                    if(d < 30) {
                        setRemainingTime(30 - d);
                        res = false;
                    }
                }
            });
            return res;
        } else {
            return true;
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
        setTimeout(() => {
            navigate('/');
        }, 1111);
    }

    const followUser = () => {
        if(!user || !auth.currentUser) {
            setBlockPost(true)
            return;
        }
        if(isLoading) { 
            return;
        }
        if(auth.currentUser && user) {
            setIsLoading(true);
            addDoc(collection(db, "follow"), {
                active: true,
                followerName: auth.currentUser.displayName, 
                followerUid: auth.currentUser.uid, 
                followingName: user.name,
                followingUid: user.uid,
                photoUrl: auth.currentUser && auth.currentUser.photoUrl ? auth.currentUser.photoURL : 'null',
              }).then(() => {
                saveNotifications(user);
                setFollowerCount(followerCount + 1);
                setIsAlreadyFollowing(true);
                updateProfileFollowers();
                updateUserFollowing();
                setIsLoading(false)
              });
        }
    }

    const getFollowers = async (u) => {
        let arr = [];
        const q = query(collection(db, "follow"), where("followingName", "==", u));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            let d = doc.data()
           if(auth.currentUser && d.followerName == auth.currentUser.displayName) {
                setIsAlreadyFollowing(true);
           }
           if((arr.findIndex(p => p.followerName == d.followerName) == -1)) {
            arr.push(d);
           }
           
        });
        setFollowerCount(arr.length)
        return arr;
    }

    const getUser = async (u) => {
        let arr = [];
        const q = query(collection(db, "users"), where("name", "==", u));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        if(arr.length > 0) {
            let data = arr[0];
            setUser(data);
        }
    }

    const logout = () => {
        signOut(auth).then(() => {
            navigate('/'); 
        });
    }

    const saveNotifications = (user) => {
        if(user && auth && auth.currentUser && auth.currentUser.displayName && (auth.currentUser.uid !== user.userId)) {
          addDoc(collection(db, "notifications"), {
              active: true,
              date: new Date(),
              photoUrl: auth.currentUser && auth.currentUser.photoURL ? auth.currentUser.photoURL : 'null',
              postId: 0,
              notificationCreator: auth.currentUser.displayName,
              notificationCreatorId: auth.currentUser.uid,
              type: 4,
              userBeingNotified: user.name,
              userBeingNotifiedId: user.uid,
          });
        }
    }

    const saveUnfollow = (id) => {
        const p = doc(db, "follow", id);
        getDoc(p).then((data) => {
            if(data.exists()) {
                updateDoc(p, {
                  active: false,
                 }).then(() => {
                  setIsAlreadyFollowing(false);
                  setFollowerCount(followerCount > 0 ? followerCount + 1 : 0);
                 });
            }
          });
    }

    const updateUser = () => {
        setCurrentUser({
          ...currentUser,
          paidPosts: [
            ...user.paidPosts, 
            {
              date: new Date, 
              id: "null",
              subscriptionId: user.uid,
            }
           ]
        });
      }

    const refetchUserData = (name) =>  {
        userFromQueryString = name;
        getUser(userFromQueryString);
        getFollowers(userFromQueryString).then(res => {
            setIsLoading(false);
        })
        setBlock(false);
    }

    const unfollowUser = async () => {
        if(!user) {
            return;
        }
        const q = query(collection(db, "follow"), where("followerName", "==", `${auth.currentUser.displayName}`), where("followingName", "==", `${user.name}`));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            saveUnfollow(doc.id)
        });
    }

    const updateProfileFollowers = () => {
        const p = doc(db, "users", user.email);
        getDoc(p).then((data) => {
          if(data.exists()) {
              updateDoc(p, {
                followers: data.followers && data.followers > 0 ? data.followers + 1 : 1
               }).then(() => {
                 return true;
               });
          }
        });
    }

    const updateUserFollowing = () => {
        const p = doc(db, "users", auth.currentUser.email);
        getDoc(p).then((data) => {
          if(data.exists()) {
              updateDoc(p, {
                following: data.followers && data.followers.length > 0 ? data.followers.length + 1 : 1
               }).then(() => {
                 return true;
               });
          }
        });
    }

    return (<>
        { !isLoading ? <div>
            <Row className="px-3 py-3">
                <Col className="d-flex px-0" onClick={() => props.setShowOverlay ? props.setShowOverlay(true) : console.log("no prop dawg")} >
                    <h6 className="camlyfe-header cursor">{user &&user.name}</h6>
                </Col>
                <Col className="cursor px-0 text-end">
                    { auth.currentUser && <><i className={`bi bi-heart mx-2`} 
                       onClick={() => navigate('/notifications')} style={{ fontSize: '17px' }} />
                    <i className="bi bi-envelope mx-2" onClick={() => navigate('/allmessages')} /></>}
                    { auth.currentUser ? <Button className="bold login-button" onClick={() => logout()} size='sm' variant='dark'>
                        Log out
                    </Button> 
                    : 
                    <Button className="bold login-button" onClick={() => navigate('/signin')} size='sm' variant='dark'>
                        Create account
                    </Button> }
                </Col>
            </Row>
            <Row>
                <Col xs="3">
                    {bubble && <div className='bubble-shadow position-relative' onClick={() => addProfilePicture()} style={bubble}>
                        {isOwnerOfProfile && <div className="edit-profile-pic">+</div>}
                    </div>}
                </Col>
                <Col className='cursor text-right' xs="9">
                    <div>
                        <Badge bg='dark' className='cursor mx-1 pinkB'>
                            Posts: {posts && posts.length > 0 ? posts.length : 0}
                        </Badge>
                    </div>
                    <div className='my-2'>
                        <Badge className='cursor mx-1' bg='dark'>
                            {remainingTime ?  `Days left on subscription: ${Math.round(remainingTime)}`
                            :
                            `Subscription price: $${user.subscriptionPrice ? user.subscriptionPrice : 0}`
                            }
                        </Badge>
                    </div>
                    <div>
                        <Badge bg='secondary' className='cursor mx-1' onClick={() => navigate(`/followers?userprofile=${user.name}`)}>
                            Followers: {followerCount}
                        </Badge>
                    </div>
                </Col>
            </Row>
            <Row className="profile-description my-2 text-white">
                <Col xs="12">
                    { user && user.description ? <div className='pb-1 px-2 pt-3'>
                            <small>{user.description && user.description.length > 0 ? user.description : 'You havent added a description yet'}</small>
                        </div> 
                        : 
                        <div className='px-2 py-3'>
                            {isOwnerOfProfile ? <small>Profile incomplete. Add a profile pic to become visible on Camlyfe.</small>
                            :
                            <small>No description added</small> }
                        </div>
                    }
                </Col>
                <Col xs="12">
                    <div className="d-flex gap-2 mb-2 mt-3">
                        {user && auth.currentUser && auth.currentUser.uid === user.uid ? 
                            <Button className='bold full-width' disabled={isLoading} onClick={() => navigate(`/editprofile?userprofile=${auth.currentUser.displayName}`)}
                                 size="sm" variant="dark">
                                Edit Profile
                            </Button> 
                            : 
                            <>
                            {block ? 
                            <Button className='bold full-width'
                            onClick={() => buyPost()}>Subscribe for ${user.subscriptionPrice}/month</Button>
                            :
                            <Button className='bold pink' disabled={isLoading} size='sm' variant='dark' 
                                    onClick={() => !isAlreadyFollowing ? followUser() : unfollowUser()} style={{ width: isAlreadyFollowing ? '50%' : '100% '}}>
                                { isAlreadyFollowing ? 'Unfollow' : 'Follow User' }
                            </Button>}
                            </>
                        }
                        { !block && isAlreadyFollowing && !isOwnerOfProfile && <Button className='bold pink' onClick={() => navigate(`/messaging?user=${user.uid}`)} 
                                        size='sm' style={{ width: '50%' }} variant='dark'>Message</Button>}
                    </div>
                </Col>
                { user && user.uid && !isOwnerOfProfile && !isAlreadyFollowing && <Col className='mb-3' xs='12'>
                    <BlockUser user={user} />
                </Col>
                }
            </Row>
            {block ? 
            <div className='full-height1 position-relative'>
            <div className="middle text-center">
                <div className='m-4'>
                    <small className='mt-5 text-pink'>Purchasing a subscription will allow you to follow this user and access their public content.</small>
                </div>
            </div>
            </div> 
            :
            <Row className="grid position-relative" style={{ paddingBottom: '100px' }}>
                {!posts || posts.length == 0 ?
                    <div className="opaque text-pink text-center">
                        <div>
                            <p className="mt-5">{isOwnerOfProfile ? `You haven't` : `This user hasn't`} posted anything yet! </p>
                            {isOwnerOfProfile && <p>click the plus button below</p>}
                        </div>
                    </div>
                :
                    <>
                        {posts && posts.map((p, idx) => {
                            return <Col className="cursor position-relative profile-grid-photo" 
                                        key={idx + p.userName} 
                                        onClick={() => auth.currentUser ? navigate(`/userfeed?postId=${p.id}&user=${encodeURI(p.userName)}`) : setBlockPost(true)} 
                                        xs="4">
                                            <GridPhoto hasAccess={(p.price == 0) || currentUser.paidPosts && currentUser.paidPosts.findIndex(p => p.id == p.userId) > -1} post={p} />
                            </Col>;
                        })}
                    </>
                }
            </Row>}
            <Row>
                <Col>
                    <Footer currentUser={user} refetchUserData={refetchUserData}/>
                </Col>
            </Row>
            <Modal centered keyboard={false} show={blockPost} static>
                <Modal.Body className='ml-2'>
                    <h4 className="my-3">Easy there, tiger</h4>
                    <p>If you want to see a user's posts, you have to create a Camlyfe account.</p>
                    <div className="mt-4 text-right">
                        <Button onClick={() => setBlockPost(false)} variant='light'>
                            cancel
                        </Button>
                        <Button className='ml-2' onClick={() => navigate('/signin?newuser="true"')}>
                            Create account
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <SEO title='User Profile' description='camlyfe user profile page' />
        </div> 
        : 
        <div className='full-height'>
            <div className="middle text-center">
                <p className='my-3 text-white'>Loading...</p>
                <Spinner className='opaque' animation="border" variant="pink" />
            </div>
        </div>}
        <Alert className="fixed-top" show={showAlert} variant="success">
            <h4 className='bold'>Sweet! We'll have your subscription ready ASAP!</h4>
            <p>This usually takes less than 2 minutes. If you have any questions or concerns, 
                please email our support team at support@camlyfe.com
            </p>
            <br/>
            <br />
            <div className="text-right">
            <Button onClick={() => closeAlert()}>ok</Button>
            </div>
        </Alert>
        </>
    )
}
export default ProfilePage;