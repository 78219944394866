

const Acceptance = () => {

    return (
        <div>
              1. ACCEPTANCE<br /><br />
              By using and/or visiting the Camlyfe website (collectively, including but not limited to all Content, Uploads and User Submissions available through camlyfe.com,
              the website) you agree to the terms and conditions contained herin and the terms and conditions of Camlyfe's privacy policy incorporated herin, and all future 
              amendments and modifications (collectively referred to as the "Agreement"). By entering, you agree to be bound by these terms and conditions. If you do not agree to 
              be bound the terms and conditions contained herein, then do not use camlyfe.com. The terms and conditions of this Agreement are subject to change by Camlyfe at any 
              time in its sole discretion and you agree be bound by all modifications, changes and/or revisions. If you do not accept to be bound by any and all modifications, changes 
              and/or revisions of this agreement, you many not use camlyfe.com. The terms and conditions contained herein apply to all users of Camlyfe whether a 'visitor' or a 'member'
              and you are only authorized to use camlyfe.com if you agree to abide by all applicable laws and be legally bound by the terms and conditions of this Agreement.
              <br /><br />
              2. DESCRIPTION The Camlyfe website allows for uploading, sharing and general viewing various types of content allowing registered and unregistered users to share and view visual 
              depictions of adult content, including sexually explicit images. In addition, Camlyfe contains video content, information and other materials posted/uploaded by users. 
              Camlyfe allows its users to view the Content and Website subject to the terms and conditions of this Agreement. The Camlyfe website may also contain certain links to third 
              party websites which are in no way owned or controlled by Camlyfe. Camlyfe assumes no responsibility for the content, privacy policies, practices of any and all third 
              party websites. Camlyfe cannot censor or edit the content of third party sites. You acknowledge that Camlyfe will not be liable for any and all liability arising for your 
              use of any third-party website. Camlyfe is for your personal use and shall not be used for any commercial endeavor except those specifically endorsed or approved by 
              camlyfe.com. Any illegal and/or unauthorized use of Camlyfe is prohibited including but not limited to collecting usernames and e-mail addresses for sending unsolicited 
              emails or unauthorized framing or linking to the Camlyfe website is prohibited.
              <br /><br />
              3. ACCESS
              In order to use this website, you affirm that you are at least eighteen (18) year of age and/or over the age of majority in the jurisdiction you reside and from which you 
              access the website where the age of majority is greater than eighteen (18) years of age. If you are under the age of 18 and/or under the age of majority in the jurisdiction 
              you reside and from which you access the website, then you are not permitted to use the website.
              <br /><br />
              4. CONDUCT You acknowledge and agree that you shall be responsible for your own user submissions and the consequences of posting, uploading, publishing, transmitting or other making 
              them available on Camlyfe. You agree that you shall not (nor others using your account) post, upload, publish, transmit or make available in any way on Camlyfe content 
              which is illegal, unlawful, harassing, harmful, threatening, tortuous, abusive, defamatory, obscene, libelous, invasive of one's privacy including but not limited to 
              personal information, hateful, racial. You also agree that you shall not post, upload, publish, transmit or make available in any way on Camlyfe software containing 
              viruses or any other computer code, files, or programs designed to destroy, interrupt, limit the functionality of, or monitor, or persistently reside in or on any 
              computer software or hardware or telecommunications equipment. You agree that you shall not (nor others using your account) post, upload, publish, transmit or make available 
              in any way on Camlyfe content which is intentionally or unintentionally violating any applicable local, state, national, or international law, or any regulations or 
              laws having the force of law where you reside and elsewhere, including but not limited to any laws or regulations relating to securities, privacy, and export control; 
              engage in, promote, You agree that you shall not (nor others using your account) post, upload, publish, transmit or make available in any way on Camlyfe content depicting 
              illegal activities, promote or depict physical harm or injury against any group or individual, or promote or depict any act of cruelty to animals; You agree not to use 
              Camlyfe in any way that exposes Camlyfe to criminal or civil liability. You agree that Camlyfe shall have the right to determine in its sole and unfettered discretion, 
              what action shall be taken in the event of any discovered or reported violation of the terms and conditions contained herein.
              <br /><br />
              5. INTELLECTUAL PROPERTY The Content contained on the Camlyfe with the exception of User Submissions including but not limited to the text, software, scripts, graphics, music, videos, photos, sounds,
              interactive features and trademarks, service marks and logos contained therein, are owned by and/or licensed to Camlyfe, subject to copyright and other intellectual property 
              rights under United States, Canada and foreign laws and international conventions. Content on the Website is provided to you AS IS for your information and personal use only 
              and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the 
              prior written consent of the respective owners. Camlyfe reserves all rights not expressly granted in and to the Website and the Content. You agree to not engage in the use, 
              copying, or distribution of any of the Content other than expressly permitted herein, including any use, copying, and/or distribution of User Submissions of third parties 
              obtained through the Website for any commercial purposes. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary 
              notices contained therein. You agree not to disable, circumvent, or otherwise interfere with security related features of the Camlyfe or features that prevent or restrict use 
              or copying of any Content or enforce limitations on use of the Camlyfe Website or the Content therein.
              <br /><br />
              6. USER SUBMISSIONS Camlyfe permits the submission of video and other communications and the hosting, sharing and publishing of such user submissions. You understand that whether or not such User 
              Submissions are published and/or uploaded, Camlyfe does not guarantee any confidentiality with respect to any submissions. Camlyfe allows/permits you to link to materials on the 
              Website for personal, non-commercial purposes only. More over, Camlyfe provides an "Embeddable Player" feature, which you may incorporate into your own personal, non-commercial 
              websites for use in accessing the materials on the Website, provided that you include a prominent link back to the Camlyfe website on the pages containing the Embeddable Player. 
              Camlyfe reserves the right to discontinue any aspect of the Camlyfe website at any time. In addition, you may not modify, build upon or block any portion of the Embeddable 
              Player in any way. You shall be solely responsible for any and all of your own User Submissions and the consequences of posting, uploading and publishing them. Furthermore, with 
              User Submissions, you affirm, represent and/or warrant that you own or retain the necessary licenses, rights, consents, and permissions to use and authorize Camlyfe to use all 
              trademarks, copyrights, trade secrets, patents, or other proprietary rights in and to any and all User Submissions to enable inclusion and use of the User Submissions in the 
              manner contemplated by the Website and these Terms of Service; and you will not post, or allow anyone else to post, any material that depicts any person under the age of 18 
              years and you have inspected and are maintaining written documentation sufficient to confirm that all subjects of your submissions are, in fact, over the age of 18 years. You 
              have the written consent, release, and/or permission of each and every identifiable individual person in the User Submission to use the name or likeness of each and every such 
identifiable individual person to enable inclusion and use of the User Submissions in the manner contemplated by the Website and these Terms of Service. For clarity, you 
retain all of your ownership rights in your User Submissions. By submitting the User Submissions to Camlyfe, you hereby grant Camlyfe a worldwide, non-exclusive, royalty-free, 
sublicenseable and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Submissions in connection with the Camlyfe 
Website and Camlyfe's (and its successor's) business, including without limitation for promoting and redistributing part or all of the Camlyfe Website (and derivative works 
thereof) in any media formats and through any media channels. You also hereby grant each user of the Camlyfe Website a non-exclusive license to access your User Submissions 
through the Website, and to use, reproduce, distribute, prepare derivative works of, display and perform such User Submissions as permitted through the functionality of the 
Website and under these Terms of Service. The foregoing license granted by you terminates once you remove or delete a User Submission from the Camlyfe Website.

In submitting material (video or other communication), you further agree that you shall not:

submit material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless you are the owner
of such rights or have permission from their rightful owner to post the material and to grant Camlyfe all of the license rights granted herein; publish falsehoods or 
misrepresentations that could damage Camlyfe or any third party; submit material that is obscene, illegal, unlawful, defamatory, libelous, harassing, hateful, racially 
or ethnically offensive, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is otherwise inappropriate;
post advertisements or solicitations of business; impersonate another person. Camlyfe does not endorse any User Submission or any opinion, recommendation, or advice 
expressed therein, and Camlyfe expressly disclaims any and all liability in connection with User Submissions. Camlyfe does not permit copyright infringing activities and 
infringement of intellectual property rights on its Website, and Camlyfe will remove all Content and User Submissions if properly notified that such Content or User 
Submission infringes on another's intellectual property rights. Camlyfe reserves the right to remove Content and User Submissions without prior notice or delay. Camlyfe 
will also terminate a User's access to its Website, if they are determined to be an infringer. While pornographic and adult content are accepted, Camlyfe also reserves the 
right to decide in its sole and unfettered discretion, whether Content or a User Submission is appropriate and complies with these Terms of Service for violations other 
than copyright infringement and violations of intellectual property law, such as, but not limited to, obscene or defamatory material, or excessive length. Camlyfe may 
remove such User Submissions and/or terminate a User's access for uploading such material in violation of these Terms of Service at any time, without prior notice and at 
its sole discretion.

You understand and acknowledge that when using camlyfe.com, you will be exposed to User Submissions from a variety of sources, and that Camlyfe is not responsible 
for the accuracy, usefulness, safety, or intellectual property rights of or relating to such User Submissions. You further understand and acknowledge that you 
may be exposed to User Submissions that are inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable 
rights or remedies you have or may have against Camlyfe with respect thereto, and agree to indemnify and hold Camlyfe, its Owners, affiliates, operators, and/or 
licensors, harmless to the fullest extent allowed by law regarding all matters related to your use of the website.

You agree that Camlyfe may at its sole discretion have the right to refuse to publish, remove, or block access to any User Submission that is available via the 
Website or other Camlyfe network or services at any time, for any reason, or for no reason at all, with or without notice.

Camlyfe provides its website as a service to its users. However, Camlyfe assumes no responsibility whatsoever for monitoring the Camlyfe Services for inappropriate 
content or conduct. If at any time Camlyfe chooses, in its sole discretion, to monitor the Camlyfe Services, however, Camlyfe assumes no responsibility for the 
content, no obligation to modify or remove any inappropriate content, and no responsibility for the conduct of the User submitting any such content. Camlyfe may 
review and delete any User Submissions that, in its sole judgment, violates this Agreement or may be otherwise offensive or illegal, or violate the rights, harm, 
or threaten the safety of any User or person not associated with the Website (collectively "Inappropriate User Submissions"). You are solely responsible for the 
User Submissions that you make visible on the Website or to any third-party website via an embedded player provided by the Website or any other material or 
information that you transmit or share with other Users or unrelated third-parties via the Website.

You further understand, acknowledge, agree and specifically authorize Camlyfe to use, reuse, post, publish or upload any User Submissions on any other website owned 
or controlled by Camlyfe or on any website with whom Camlyfe has an agreement with respect to User Submissions content or sponsor uploaded video. In addition, User 
submission is deemed to include any sponsored or otherwise branded uploaded videos. Camlyfe reserves the right to determine as its discretion on what basis would a 
User submissions or an uploaded video might be shared with other websites (rating, number of views, user reviews, etc.).

7. ACCOUNT TERMINATION POLICY<br /><br />
A User's access to Camlyfe will be terminated if, under appropriate conditions, the User is determined to infringe repeatedly. Camlyfe reserves the right to decide 
whether Content or if a User's Submission is appropriate and complies with these Terms and Conditions in regards to violations other than copyright infringement or 
privacy law, such as, but not limited to, hate crimes, pornography, obscene or defamatory material, or excessive length. Camlyfe may remove such User Submissions 
and/or terminate a User's access for uploading such material in violation of these Terms and Conditions at any time, without prior notice and at its sole discretion.

8. POLICY<br /><br />
Camlyfe abides by a ZERO TOLERANCE policy relating to any illegal content. Child Pornography, bestiality, rape, torture, snuff, death and/or any other type of obscene 
and/or illegal material shall not be tolerated by Camlyfe. Camlyfe shall not condone child pornography and will cooperate with all governmental agencies that seek those 
who produce child pornography.

9. FEES<br /><br />
You acknowledge that Camlyfe reserves the right to charge for Camlyfe services and to change its fees from time to time in its discretion. Further more, in the event 
Camlyfe terminates your rights to use the website because of a breach of this Agreement, you shall not be entitled to the refund of any unused portion of subscription fees.

10. WARRANTIES<br /><br />
You represent and warrant that all of the information provided by you to Camlyfe to participate in the Camlyfe website is accurate and current and you have all necessary right, 
power, and authority to enter into this Agreement and to perform the acts required of you hereunder.

As a condition to using the Camlyfe, you must agree to the terms of Camlyfe's privacy policy and its modifications. You acknowledge and agree that the technical processing 
and transmission of the Website, including your User Submissions, may involve transmissions over various networks; and changes to conform and adapt to technical 
requirements of connecting networks or devices. You further acknowledge and agree that other data collected and maintained by Camlyfe with regard to its users may be 
disclosed in accordance with the Camlyfe Privacy Policy.

11. WARRANTY DISCLAIMER<br /><br />
YOU AGREE THAT YOUR USE OF THE Camlyfe WEBSITE SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, camlyfe.com, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS 
DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF. camlyfe.com MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY 
OR COMPLETENESS OF THIS SITE'S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS, MISTAKES, OR INACCURACIES 
OF CONTENT, PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, ANY UNAUTHORIZED ACCESS TO OR USE OF OUR 
SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITE, 
ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, AND/OR ANY ERRORS OR OMISSIONSIN ANY CONTENT OR FOR 
ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE Camlyfe WEBSITE. Camlyfe 
DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE Camlyfe WEBSITE OR ANY 
HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND Camlyfe WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN 
YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST 
JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. 
YOU SPECIFICALLY ACKNOWLEDGE THAT Camlyfe SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE 
RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.

12. LIMITATION OF LIABILITY<br /><br />
IN NO EVENT SHALL Camlyfe, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES 
WHATSOEVER RESULTING FROM ANY ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND 
USE OF OUR WEBSITE, ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, ANY 
INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITE, ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY 
THIRD PARTY, AND/OR ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, 
OR OTHERWISE MADE AVAILABLE VIA THE Camlyfe WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE 
POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. YOU SPECIFICALLY 
ACKNOWLEDGE THAT Camlyfe SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE 
FROM THE FOREGOING RESTS ENTIRELY WITH YOU.

13. INDEMNITY<br /><br />
You agree to defend, indemnify and hold harmless Camlyfe, its parent corporation, officers, directors, employees and agents, from and against any and all claims, damages, 
obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:

your use of and access to the Camlyfe Website;
your violation of any term of these Terms of Service;
your violation of any third party right, including without limitation any copyright, property, or privacy right; or
any claim that one of your User Submissions caused damage to a third party.
This defense and indemnification obligation will survive these Terms of Service and your use of the Camlyfe Website.

You affirm that you are either more than 18 years of age or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into 
the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms and 
Conditions contained herein.
<br /><br />
14. ASSIGNMENT
The Terms and Conditions contained herein and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Camlyfe without 
restriction. If any term, clause or provision of the agreement is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the 
validity or operation of any term, clause or provision and such invalid term, clause or provision shall be deemed to be severed from this Agreement.Terms & Conditions
      </div>
    )
}

export default Acceptance;